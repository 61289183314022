/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://x8l97tr72g.execute-api.ap-northeast-1.amazonaws.com/test",
            "region": "ap-northeast-1"
        },
        {
            "name": "commandApi",
            "endpoint": "https://vvcsde64h3.execute-api.ap-northeast-1.amazonaws.com/test",
            "region": "ap-northeast-1"
        },
        {
            "name": "stripeWebhook",
            "endpoint": "https://tqo72vosdb.execute-api.ap-northeast-1.amazonaws.com/test",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rz2ntzq37vb2zm7rlcdm36myeq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-75o7wbytnvap7iui56tuykaoqm",
    "aws_cognito_identity_pool_id": "ap-northeast-1:9d048dd0-9cdb-4b04-b2ab-6d7f5bd2a88e",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_ZyP8gnh9m",
    "aws_user_pools_web_client_id": "1jdl5phm7foqi4tjhpusi8gv4r",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "badesk-storage100749-test",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
